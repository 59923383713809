.screen-lock {
  height: 100%;
  left: 0;
  top: 0;
  position: fixed;
  width: 100%;
  z-index: 9998;
  opacity: 0.5;
  background: gray;
}

.screen-lock-loader {
  left: calc(50% - 50px);
  top: calc(50% - 50px);
  position: fixed;
  opacity: 1;
  z-index: 9999;
}
