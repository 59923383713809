.height-100 {
  height: 100%;
}

.no-padding {
  padding: 0;
}

.contents {
  padding: 20px;
  margin-left: 0;
}

.menu {
  background-color: whitesmoke;
  border-right: lightgray 1px solid;
  padding-top: 10px;
  padding-bottom: 10px;
}
